import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Alert,
    AlertTitle,
    LinearProgress
} from '@mui/material';
import {
    Dashboard as DashboardIcon,
    AssignmentTurnedIn,
    TrendingUp,
    People,
    AttachMoney,
    EmojiEvents,
    ManageAccounts,
    LiveHelp,
    QuestionAnswer,
    CloudDownload
} from '@mui/icons-material';
import useAuth from 'hooks/useAuth';
import SurveyService from 'services/survey.service';
import MiscService from 'services/misc.service';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';
import { IconServerOff, IconTrophy } from '@tabler/icons';
import transformToJqDatatable from 'utils/data-transformers';
import DismissibleAlert from 'views/components/DismissibleAlert';

const DashBoard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [recentSurveys, setRecentSurveys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const columns = React.useRef([
        { field: 'survey_title' },
        { field: 'start_date' },
        { field: 'end_date' },
        { field: 'quota' },
        { field: 'created_at' },
        { field: 'survey_status' },
        { field: 'survey_token' }
    ]);

    const [paginationProp, setPaginationProp] = React.useState({
        direction: true,
        sortBy: 'created_at',
        searchBy: 'survey_title',
        page: 0,
        rowsPerPage: 5,
        rowsNumber: 5,
        filter: null,
        singleFilterValue: null
    });

    const fetchData = useCallback(async () => {
        try {
            const params = transformToJqDatatable({ columns: columns.current, pagination: paginationProp });
            const [dashboardResponse, surveysResponse] = await Promise.all([
                MiscService.getDashboardData(user?.id),
                SurveyService.list(params)
            ]);
            setDashboardData(dashboardResponse.data);
            setRecentSurveys(surveysResponse.data.data);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [user, paginationProp]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const StatItem = ({ icon: Icon, label, value, color }) => (
        <ListItem sx={{ m: 0, pl: 0 }}>
            <ListItemIcon>
                <Icon style={{ color }} />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="h4">{value}</Typography>} secondary={label} />
        </ListItem>
    );

    StatItem.propTypes = {
        icon: PropTypes.elementType.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        color: PropTypes.string.isRequired
    };

    const QuotaBar = ({ quota }) => {
        const percentageFinder = (value) => {
            const [numerator, denominator] = value.split(' / ').map(Number);
            return (numerator / denominator) * 100;
        };

        const generateColorOnText = (value) => {
            const [numerator, denominator] = value.split(' / ').map(Number);
            return `<span style="color: #f76d6d">${numerator}</span> / <span style="color: #354154">${denominator}</span>`;
        };
        return (
            <Box sx={{ width: '100%', display: 'block' }}>
                <Box>
                    <Typography variant="body2" color="primary" dangerouslySetInnerHTML={{ __html: generateColorOnText(quota) }} />
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={percentageFinder(quota)}
                    color="primary"
                    sx={{
                        width: '100%',
                        height: 5,
                        backgroundColor: '#e0e0e0',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#354154'
                        }
                    }}
                />
            </Box>
        );
    };

    QuotaBar.propTypes = {
        quota: PropTypes.string.isRequired
    };

    const QuickAction = ({ icon: Icon, title, description, action }) => (
        <Box sx={{ mb: 2, mt: '16px' }}>
            <Typography variant="subtitle1" component="h3" gutterBottom>
                <Icon /> {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                {description}
            </Typography>
            {action}
        </Box>
    );

    QuickAction.propTypes = {
        icon: PropTypes.elementType.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        action: PropTypes.node.isRequired
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: 'background.default' }}>
            <DismissibleAlert
                severity="info"
                title="Hey! Just to let you know you can collect 100 responses this month for free."
                action={
                    <Button color="primary" variant="text" size="small" sx={{ mr: 1, color: 'primary.main', textDecoration: 'underline' }}>
                        Get more responses
                    </Button>
                }
            />

            <Grid container spacing={3}>
                {/* Dashboard Overview */}
                <Grid item xs={12} md={2}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Dashboard Overview
                            </Typography>
                            <List>
                                <StatItem
                                    icon={AssignmentTurnedIn}
                                    label="Total Surveys"
                                    value={dashboardData.surveys}
                                    color={theme.palette.primary.main}
                                />
                                <StatItem
                                    icon={People}
                                    label="Total Responses"
                                    value={dashboardData.answers}
                                    color={theme.palette.primary.main}
                                />
                                <StatItem
                                    icon={AttachMoney}
                                    label="Total Spending"
                                    value={`$${dashboardData.spending}`}
                                    color={theme.palette.primary.main}
                                />
                                <StatItem
                                    icon={TrendingUp}
                                    label="Response Rate"
                                    value={`${dashboardData.response_rate}%`}
                                    color={theme.palette.primary.main}
                                />

                                <StatItem
                                    icon={IconTrophy}
                                    label="Response Filled"
                                    value={`${dashboardData.response_filled}`}
                                    color={theme.palette.primary.main}
                                />
                                <StatItem
                                    icon={CloudDownload}
                                    label="Responses Remaining"
                                    value={`${dashboardData.response_remained}`}
                                    color={theme.palette.primary.main}
                                />
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Recent Activity */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Recent Activity
                            </Typography>
                            {recentSurveys && recentSurveys.length > 0 ? (
                                <>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            <Typography sx={{ flex: '1 1 40%' }} variant="h6">
                                                Survey
                                            </Typography>

                                            <Typography sx={{ flex: '1 1 5%' }} variant="h6">
                                                Quota Available
                                            </Typography>
                                            <Typography sx={{ flex: '0 0 20%', textAlign: 'right' }} variant="h6">
                                                Action
                                            </Typography>
                                        </ListItem>
                                        <Divider />
                                        {recentSurveys.map((survey, index) => (
                                            <React.Fragment key={survey.survey_token}>
                                                <ListItem sx={{ m: 0, p: 0 }}>
                                                    <ListItem
                                                        button
                                                        component={RouterLink}
                                                        to={`/${survey.survey_token}/advance-form-create`}
                                                        sx={{ m: 0, p: '10px' }}
                                                    >
                                                        <ListItemIcon>
                                                            <DashboardIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={survey.survey_title}
                                                            secondary={`Status: ${survey.survey_status}`}
                                                        />
                                                    </ListItem>
                                                    <ListItem sx={{ flex: '1 1 60%' }}>
                                                        <QuotaBar quota={survey.quota || 0} />
                                                    </ListItem>
                                                    <Box sx={{ flex: '0 0 20%', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            to={`/${survey.survey_token}/advance-form-create`}
                                                            sx={{ color: '#354154' }}
                                                            component={RouterLink}
                                                        >
                                                            Manage
                                                        </Button>
                                                    </Box>
                                                </ListItem>
                                                {index < recentSurveys.length - 1 && <Divider />}
                                            </React.Fragment>
                                        ))}
                                    </List>

                                    <Box sx={{ display: 'flex' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ mr: 2, color: 'white', boxShadow: 'none' }}
                                            onClick={() => navigate('/surveys/add')}
                                            color="secondary"
                                        >
                                            Create Survey
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => navigate('/surveys')}
                                            color="primary"
                                            sx={{ boxShadow: 'none' }}
                                        >
                                            View Surveys
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 200,
                                        textAlign: 'center'
                                    }}
                                >
                                    <IconServerOff size={60} sx={{ color: 'text.primary', mb: 2 }} />
                                    <Typography variant="h6" color="text.primary" gutterBottom>
                                        No Surveys Available
                                    </Typography>
                                    <Typography variant="body2" color="text.primary">
                                        Create your first survey to get started!
                                    </Typography>
                                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate('/surveys/add')}>
                                        Create Survey
                                    </Button>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Quick Actions */}
                <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom mb={3}>
                                Quick Actions
                            </Typography>
                            <QuickAction
                                icon={ManageAccounts}
                                title="Manage Profile"
                                description="Update your profile information."
                                action={
                                    <Button variant="outlined" size="small" href="/profile" sx={{ color: '#354154', margin: '8px', ml: 0 }}>
                                        Edit Profile
                                    </Button>
                                }
                            />
                            <QuickAction
                                icon={LiveHelp}
                                title="Tips & Tricks"
                                description="Learn how to create effective surveys."
                                action={
                                    <GlobalModalHelpButton
                                        title="View Tutorials"
                                        content="https://kb.surveyflip.com"
                                        button={
                                            <Button variant="outlined" size="small" sx={{ color: '#354154' }}>
                                                View Tutorials
                                            </Button>
                                        }
                                    />
                                }
                            />
                            <QuickAction
                                icon={QuestionAnswer}
                                title="Knowledge Base"
                                description="Find answers to common questions."
                                action={
                                    <GlobalModalHelpButton
                                        title="Explore Knowledge Base"
                                        content="https://kb.surveyflip.com"
                                        button={
                                            <Button variant="outlined" size="small" sx={{ color: '#354154' }}>
                                                Explore KB
                                            </Button>
                                        }
                                    />
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashBoard;
